import {
    JssProvider,
    ThemeProvider as JssThemeProvider,
    SheetsRegistry,
} from "react-jss"
import React from "react"
import Theme from "./theme"
import { create as createJss } from "jss"
import preset from "jss-preset-default"

interface ThemeProviderProps {
    registry?: SheetsRegistry
}

const jss = createJss(
    preset({
        defaultUnit: {
            margin: "rem",
            "margin-top": "rem",
            "margin-right": "rem",
            "margin-bottom": "rem",
            "margin-left": "rem",
            padding: "rem",
            "padding-top": "rem",
            "padding-right": "rem",
            "padding-bottom": "rem",
            "padding-left": "rem",
        },
    }),
)

const theme = new Theme()

const ThemeProvider: React.FunctionComponent<ThemeProviderProps> = ({
    children,
    registry,
}) => {
    return (
        <JssProvider jss={jss} registry={registry}>
            <JssThemeProvider theme={theme}>{children}</JssThemeProvider>
        </JssProvider>
    )
}

export default ThemeProvider
