import { GatsbyBrowser, WrapRootElementBrowserArgs } from "gatsby"
import React from "react"
import ThemeProvider from "~app/theme/provider"

export const onInitialClientRender: GatsbyBrowser["onInitialClientRender"] =
    (): void => {
        const ssStyles = window.document.getElementById(`server-side-jss`)
        ssStyles?.parentNode?.removeChild(ssStyles)
    }

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
    element,
}: WrapRootElementBrowserArgs) => <ThemeProvider>{element}</ThemeProvider>

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = () => false
