import "@fontsource/open-sans/400.css"
import "@fontsource/open-sans/700.css"
import { JssStyle } from "jss"
import Typography from "typography"
import typographyTheme from "./typography"

export type breakpoint = "xs" | "sm" | "md" | "lg" | "xl" | "xxl"

interface StyleObject {
    [x: string]: JssStyle
}

class Grid {
    breakpoints: Map<breakpoint, string> = new Map([
        ["xs", "0"],
        ["sm", "640px"],
        ["md", "768px"],
        ["lg", "1024px"],
        ["xl", "1280px"],
        ["xxl", "1536px"],
    ])

    row(): JssStyle {
        return {
            display: "flex",
            flexWrap: "wrap",
        }
    }

    column(size: number, columns = 5): JssStyle {
        return {
            flex: "0 0 auto",
            width: `${(size / columns) * 100}%`,
        }
    }

    breakpointAndUp(key: breakpoint): string {
        return `@media (min-width: ${this.breakpoints.get(key)})`
    }
}

export default class Theme {
    darkenRatio = 0.1

    paperColor = "#ffffff"
    textColor = "#575655"
    /**
     * TODO: create a proper scale, because this isn't a real scale
     */
    singlePlotScale = {
        '50': '#fbfbfb', 
        '100': '#f7f7f7', 
        '200': '#ebebeb', 
        '300': '#dfdfdf', 
        '400': '#c7c7c7', 
        '500': '#afafaf', 
        '600': '#9e9e9e', 
        '700': '#575655', 
        '800': '#696969', 
        '900': '#575655'
    }

    blauColor = "#2085bf"
    blauColorScale =  {
        '50': '#f4f9fc', 
        '100': '#e9f3f9', 
        '200': '#c7e1ef', 
        '300': '#a6cee5', 
        '400': '#63aad2', 
        '500': '#2085bf', 
        '600': '#1d78ac', 
        '700': '#18648f', 
        '800': '#135073', 
        '900': '#10415e'
    }
    blauColorTranslucent = "#2085bfcc"
    
    hellblauColor = "#4eb2e5"
    hellblauColorScale = {
        '50': '#f6fbfe', 
        '100': '#edf7fc', 
        '200': '#d3ecf9', 
        '300': '#b8e0f5', 
        '400': '#83c9ed', 
        '500': '#4eb2e5', 
        '600': '#46a0ce', 
        '700': '#3b86ac', 
        '800': '#2f6b89', 
        '900': '#265770'
    }
    hessenblauColor = "#004996"
    hessengoldColor = "#edba36"
    hessenrotColor = "#d3242e"

    headingFont = "Open Sans"
    bodyFont = "Open Sans"

    gutter = 1.5

    grid: Grid
    typography: Typography

    constructor() {
        this.grid = new Grid()
        this.typography = new Typography(typographyTheme(this))
    }

    globalCss(options: StyleObject): JssStyle {
        const styles = { ...this.typography.toJSON() } as StyleObject

        for (const key in options) {
            styles[key] = { ...styles[key], ...options[key] }
        }
        return styles
    }
}
