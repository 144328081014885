import Theme from "./theme"
import { TypographyOptions } from "typography"

interface Rule {
    [key: string]: string | number | undefined
}

interface RuleSheet {
    [key: string]: Rule
}

const typographyTheme = (theme: Theme): TypographyOptions => ({
    baseFontSize: "18px",
    baseLineHeight: 1.5,
    headerFontFamily: [theme.headingFont, "sans-serif"],
    bodyFontFamily: [theme.bodyFont, "sans-serif"],
    headerColor: theme.textColor,
    bodyColor: theme.textColor,
    headerWeight: "600",
    headerLineHeight: 1.5,
    bodyWeight: 400,
    boldWeight: 700,
    overrideStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => {
        const value: RuleSheet = {
            a: {
                color: theme.blauColor,
                textDecoration: "none",
                backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 1px, ${theme.blauColor} 1px, ${theme.blauColor} 2px, rgba(0, 0, 0, 0) 2px)`,
            },
            "a:hover,a:active": {
                textShadow: "none",
                backgroundImage: "none",
            },

            "h2,h3,h4,h5,h6": {
                marginTop: rhythm(1.5),
                marginBottom: rhythm(0.5),
                fontWeight: 600,
                color: theme.blauColor,
            },

            blockquote: {
                ...scale(1 / 5),
                borderLeft: `${rhythm(6 / 16)} solid ${theme.hellblauColor}`,
                color: theme.textColor,
                paddingLeft: rhythm(10 / 16),
                fontStyle: "italic",
                marginLeft: 0,
                marginRight: 0,
            },
            "blockquote > :last-child": {
                marginBottom: 0,
            },
            "blockquote cite": {
                ...adjustFontSizeTo(options.baseFontSize),
                color: options.bodyColor,
                fontStyle: "normal",
                fontWeight: options.bodyWeight,
            },
            "blockquote cite:before": {
                content: '"— "',
            },
        }
        if (process.env.TYPOGRAPHY_DEBUG) {
            value["body"] = {
                background:
                    "repeating-linear-gradient(to bottom, transparent, transparent 7px, rgba(0, 0, 0, 0.1) 8px)",
            }
        }
        return value
    },
})

export default typographyTheme
